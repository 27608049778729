import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { defaultLightTheme, defaultDarkTheme } from 'src/theme';
import styled, { ThemeProvider } from 'styled-components/native';
import { Provider as PaperProvider } from 'react-native-paper';
import { useColorScheme } from 'react-native';
import useViewMode from 'src/hooks/useViewMode';
import _ from 'lodash';
import { BasicColors } from 'src/interfaces';
import { AppTheme } from 'src/types/paper';

export interface Context extends AppTheme {
  setThemeSet: Dispatch<SetStateAction<ThemeSet>>;
  removePreview: () => void;
  previewThemeSet: Dispatch<SetStateAction<ThemeSet | undefined>>;
  applyDefaultTheme: () => void;
}

interface ThemeSet {
  dark: BasicColors;
  light: BasicColors;
}

const defaultThemeColors: ThemeSet = {
  light: defaultLightTheme.colors,
  dark: defaultDarkTheme.colors
};

export const defaultContext: Context = {
  ...defaultLightTheme,
  setThemeSet: _.noop,
  removePreview: _.noop,
  previewThemeSet: _.noop,
  applyDefaultTheme: _.noop
};

export const ThemeContext = createContext<Context>(defaultContext);

export const useAppTheme = (): Context => useContext(ThemeContext);
export const ThemeConsumer = ThemeContext.Consumer;

const AppThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const colorScheme = useColorScheme();
  const viewMode = useViewMode();

  const [themeSet, setThemeSet] = useState<ThemeSet>(defaultThemeColors);
  const [previewSet, previewThemeSet] = useState<ThemeSet | undefined>();

  const removePreview = useCallback(() => previewThemeSet(undefined), []);
  const applyDefaultTheme = useCallback(() => setThemeSet(defaultThemeColors), []);

  useEffect(() => {
    previewThemeSet(undefined);
  }, [themeSet]);

  const theme: AppTheme = useMemo(() => {
    const t = previewSet ?? themeSet;
    if (colorScheme === 'light') {
      return {
        ...defaultLightTheme,
        colors: _.defaultsDeep(t.light, defaultThemeColors.light),
        viewMode
      };
    }
    return {
      ...defaultDarkTheme,
      colors: _.defaultsDeep(t.dark, defaultThemeColors.dark),
      viewMode
    };
  }, [previewSet, themeSet, colorScheme, viewMode]);

  const context = useMemo(
    () => ({
      ...theme,
      setThemeSet,
      previewThemeSet,
      removePreview,
      applyDefaultTheme
    }),
    [theme, setThemeSet, previewThemeSet, removePreview, applyDefaultTheme]
  );
  return (
    <ThemeContext.Provider value={context}>
      <ThemeProvider theme={theme}>
        <PaperContainer>
          <PaperProvider theme={theme}>{children}</PaperProvider>
        </PaperContainer>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default AppThemeProvider;

const PaperContainer = styled.View`
  flex: 1;
  overflow: hidden;
`;
