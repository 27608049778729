import React, { useLayoutEffect } from 'react';
import { ContentContainer, InputContainer, MainText, Row, FormScrollView } from './shared';
import { Headline, HoverButton, TextInput } from 'src/components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import SelectPatient from './SelectPatient';
import { ActivityIndicator } from 'react-native-paper';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { LayoutAnimation } from 'react-native';
import { FormData, FormState } from '../model';
import { getIn } from 'formik';

const PatientInfo = function <StateEnum extends number, Data extends FormData>(
  props: FormState<StateEnum, Data>
) {
  const {
    practiceInfo,
    values: { clientInfo, patientInfo },
    clientPatientQuery: { mutateAsync, variables },
    setFieldValue,
    validateField,
    errors,
    clientPatientQuery
  } = props;
  const { t } = useTranslation('onlineBooking');
  const { colors } = useAppTheme();

  useLayoutEffect(() => {
    if (clientInfo.emailAddress !== variables?.email) {
      void mutateAsync({
        email: clientInfo.emailAddress,
        practiceId: practiceInfo.practiceId
      });
    }
  }, [clientInfo.emailAddress, mutateAsync, practiceInfo.practiceId, variables?.email]);

  useLayoutEffect(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }, [patientInfo]);

  if (clientPatientQuery.isLoading) {
    return <ActivityIndicator color={colors.primary} size='large' style={{ flex: 1 }} />;
  }

  if (!clientPatientQuery.data?.patients?.length || !!patientInfo?.isNewPatient) {
    return (
      <FormScrollView>
        <ContentContainer>
          <MainText as={Headline}>{t('patientInfo.title')}</MainText>
          <MainText>{t('patientInfo.description')}</MainText>
        </ContentContainer>
        <Row>
          <InputContainer halfSize>
            <TextInput
              label={t('form.name')}
              value={patientInfo?.name}
              onChangeText={async (text) => setFieldValue('patientInfo.name', text, false)}
              onBlur={async () => validateField('patientInfo.name')}
              error={!!getIn(errors, 'patientInfo.name')}
              msg={t(getIn(errors, 'patientInfo.name'))}
            />
          </InputContainer>
          <InputContainer halfSize>
            <TextInput
              label={t('form.breed')}
              value={patientInfo?.breed}
              onChangeText={async (text) => setFieldValue('patientInfo.breed', text, false)}
              onBlur={async () => validateField('patientInfo.breed')}
              error={!!getIn(errors, 'patientInfo.breed')}
              msg={t(getIn(errors, 'patientInfo.breed'))}
            />
          </InputContainer>
          <InputContainer halfSize>
            <TextInput
              label={t('form.sex')}
              value={patientInfo?.sex}
              onChangeText={async (text) => setFieldValue('patientInfo.sex', text, false)}
              onBlur={async () => validateField('patientInfo.sex')}
              error={!!getIn(errors, 'patientInfo.sex')}
              msg={t(getIn(errors, 'patientInfo.sex'))}
            />
          </InputContainer>
          <InputContainer halfSize>
            <TextInput
              label={t('form.age')}
              value={patientInfo?.age}
              onChangeText={async (text) => setFieldValue('patientInfo.age', text, false)}
              onBlur={async () => validateField('patientInfo.age')}
              error={!!getIn(errors, 'patientInfo.age')}
              msg={t(getIn(errors, 'patientInfo.age'))}
            />
          </InputContainer>
        </Row>
        {!!clientPatientQuery.data?.patients?.length && (
          <StyledButton onPress={async () => setFieldValue('patientInfo.isNewPatient', undefined)}>
            {t('patientInfo.selectExisting')}
          </StyledButton>
        )}
      </FormScrollView>
    );
  }
  return <SelectPatient {...props} />;
};

export default PatientInfo;

const StyledButton = styled(HoverButton).attrs({
  compact: true
})`
  align-self: flex-start;
`;
