import React, { Fragment, useMemo } from 'react';
import { Body, Subheading } from 'src/components';
import { isDefined } from 'src/utils';
import EditButton from './EditButton';
import { NoticeContainer, ErrorText, MainText } from './shared';
import styled, { css } from 'styled-components/native';
import { Margin } from 'src/constants';
import { Weight } from 'src/theme';
import { useTranslation } from 'react-i18next';
import { FormData, FormState, Section } from '../model';
import { FormikErrors, getIn } from 'formik';

interface Props<StateEnum extends number, Data extends FormData>
  extends FormState<StateEnum, Data> {
  section: Section<StateEnum, Data>;
  disableEditing?: boolean;
  errors: FormikErrors<Data>;
  values: Data;
  goToStep: (to: StateEnum) => void;
}
const ConfirmationSection = function <StateEnum extends number, Data extends FormData>({
  disableEditing,
  section,
  ...props
}: Props<StateEnum, Data>): ReturnType<React.FC<Props<StateEnum, Data>>> {
  const { errors, values, goToStep } = props;
  const { t } = useTranslation('onlineBooking');
  const { title, fields, stateKey, fieldDisplay, dataKey } = section;

  const labelValues = useMemo(() => {
    const valueSection = values[dataKey];
    const errorsSection = errors[dataKey];
    return Object.keys(fields)
      .map((field) => {
        const value = getIn(valueSection, field);
        const error = getIn(errorsSection, field);
        return { field: field as keyof typeof fields, value, error };
      })
      .filter(({ value, error }) => isDefined(value) || error);
  }, [fields, values, errors, dataKey]);

  return (
    <NoticeContainer>
      <Container fieldDisplay='row'>
        <MainText as={Subheading}>{t(title)}</MainText>
        {!disableEditing && <EditButton onPress={() => goToStep(stateKey)} />}
      </Container>
      <Column>
        {labelValues.map(({ field, value, error }) => {
          if (!isDefined(value) && !error) {
            return null;
          }
          const transformer = fields[field];
          const transformedValue = typeof transformer === 'function' ? transformer?.(props) : value;

          return (
            <Fragment key={String(field)}>
              <Container fieldDisplay={fieldDisplay}>
                <SectionTitle fieldDisplay={fieldDisplay}>
                  {t(`form.${String(field)}`)}
                </SectionTitle>
                {transformedValue &&
                  (typeof transformedValue === 'string' || typeof transformedValue === 'number' ? (
                    <Value>{t(`${transformedValue}`)}</Value>
                  ) : (
                    transformedValue
                  ))}
              </Container>
              {error && <ErrorText>{t(error)}</ErrorText>}
            </Fragment>
          );
        })}
        {!labelValues.length && <Value>{t('common:n/a')}</Value>}
        {!!errors[dataKey] && <ErrorText>{t(`errors.${String(dataKey)}`)}</ErrorText>}
      </Column>
    </NoticeContainer>
  );
};

export default ConfirmationSection;

const Container = styled.View<{ fieldDisplay: 'row' | 'column' }>`
  ${({ fieldDisplay }) => (fieldDisplay === 'row' ? RowStyle : ColumnStyle)}
`;

const RowStyle = css`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ColumnStyle = css`
  flex-direction: column;
  gap: ${Margin.Medium}px;
`;

const Column = styled.View`
  flex-direction: column;
  gap: ${Margin.Large}px;
`;

const SectionTitle = styled(Body)<{ fieldDisplay: 'row' | 'column' }>`
  color: ${({ theme }) => (theme.viewMode.isMobile ? theme.colors.text : theme.colors.onSurface)};
  ${({ fieldDisplay }) => fieldDisplay === 'column' && `font-weight: ${Weight.BOLD};`}
`;

const Value = styled(Body)`
  color: ${({ theme }) => (theme.viewMode.isMobile ? theme.colors.text : theme.colors.onSurface)};
`;
