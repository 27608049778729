import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { defaultLanguage, languagesResources } from './languageConfig';
import RNLanguageDetector from './languageDetector';

void i18n
  .use(RNLanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    resources: languagesResources,
    compatibilityJSON: 'v3',
    fallbackLng: defaultLanguage,
    ns: [
      'addToCalendar',
      'afterHours',
      'appError',
      'appointments',
      'articleHub',
      'changePassword',
      'changePasswordModal',
      'common',
      'contactAlly',
      'contactUs',
      'discardChanges',
      'emailConfirmation',
      'eula',
      'forcedUpdate',
      'genericError',
      'home',
      'informationUpdate',
      'login',
      'loyalty',
      'onboardingErrors',
      'onlineBooking',
      'patientProfile',
      'permissions',
      'petID',
      'petList',
      'petPageDisabled',
      'petProfile',
      'practiceClientSearch',
      'profileClientPracticeConfig',
      'profileRoot',
      'pushNotifications',
      'requests',
      'selectPractice',
      'settings',
      'timeReference',
      'todos',
      'tokenPasswordReset',
      'userInfo',
      'userSearch',
      'validation'
    ],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false,
      transSupportBasicHtmlNodes: false
    }
  });

export default i18n;
