import LottieView from 'lottie-react-native';
import { useMemo } from 'react';
import { ActivityIndicator } from 'react-native-paper';
import { Body } from 'src/components';
import AnimalLoader from 'src/components/AnimalLoader';
import { Species, IconSize, Margin, MOBILE_WIDTH_SMALL } from 'src/constants';
import { lottieWholeColorReplace } from 'src/utils/lottie';
import styled from 'styled-components/native';
import LoadingAnimation from '../assets/loading_animation.json';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { useTranslation } from 'react-i18next';
import { OnlineBookingState } from '../BookingProvider/model';

const AppointmentLoader: React.FC<OnlineBookingState> = ({
  practiceInfo: { appointmentBooking }
}) => {
  const { colors } = useAppTheme();
  const { t } = useTranslation('onlineBooking');

  const animationColorReplaced = useMemo(() => {
    return lottieWholeColorReplace(LoadingAnimation, colors.primary);
  }, [colors.primary]);

  const showDogAndCatLoader = appointmentBooking?.loader === 'dog_and_cat';
  const showAnimalLoader = Object.values(Species).includes(appointmentBooking?.loader as Species);
  const showEmptyLoader = !showDogAndCatLoader && !showAnimalLoader;
  return (
    <LoadingContainer>
      {showDogAndCatLoader && (
        <LottieView
          webStyle={{
            fill: colors.primary
          }}
          style={{ aspectRatio: 2.6, width: '60%' }}
          resizeMode='contain'
          renderMode='AUTOMATIC'
          source={animationColorReplaced}
          autoPlay
          loop
        />
      )}
      {showAnimalLoader && (
        <AnimalLoader
          color={colors.primary}
          size={IconSize.XL}
          species={appointmentBooking?.loader}
        />
      )}
      {showEmptyLoader && <ActivityIndicator color={colors.primary} size={IconSize.XL} />}
      <CenteredText>{t('selectAppointment.loading')}</CenteredText>
    </LoadingContainer>
  );
};

export default AppointmentLoader;

const LoadingContainer = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: ${Margin.Large}px;
  max-width: ${MOBILE_WIDTH_SMALL}px;
`;

const CenteredText = styled(Body)`
  color: ${({ theme }) => theme.colors.onSurface};
  text-align: center;
`;
