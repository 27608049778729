import { BOOKING_STEPS } from './enums';
import { OnlineBookingFormData, OnlineBookingState } from '../model';
import {
  additionalInfoSchema,
  appointmentSchema,
  clientSchema,
  notificationSchema,
  patientSchema,
  validationSchema
} from '../validation';
import BookingStatus from '../../components/BookingStatus';
import { ComponentProps } from 'react';
import SelectAppointment from '../../components/SelectAppointment';
import {
  Confirmation,
  ClientInformation,
  PatientInfo,
  NotificationSettings,
  AdditionalInfo
} from 'src/module/FormKit/components';

const STEP_INFO: {
  [Step in BOOKING_STEPS]: {
    title: `section.${string}`;
    field: keyof OnlineBookingFormData | null;
    validator: (data: Partial<OnlineBookingFormData>) => boolean;
    key: Step;
    component: React.FC<
      OnlineBookingState & ComponentProps<typeof Confirmation<BOOKING_STEPS, OnlineBookingFormData>>
    >;
  };
} = {
  [BOOKING_STEPS.CLIENT_INFO]: {
    title: 'section.clientInfo',
    field: 'clientInfo',
    validator: (data) => clientSchema.isValidSync(data.clientInfo),
    key: BOOKING_STEPS.CLIENT_INFO,
    component: ClientInformation
  },
  [BOOKING_STEPS.PATIENT_INFO]: {
    title: 'section.patientInfo',
    field: 'patientInfo',
    validator: (data) => patientSchema.isValidSync(data.patientInfo),
    key: BOOKING_STEPS.PATIENT_INFO,
    component: PatientInfo
  },
  [BOOKING_STEPS.NOTIFICATION_SETTINGS]: {
    title: 'section.notificationSettings',
    field: 'notificationSettings',
    validator: (data) => notificationSchema.isValidSync(data.notificationSettings),
    key: BOOKING_STEPS.NOTIFICATION_SETTINGS,
    component: NotificationSettings
  },
  [BOOKING_STEPS.ADDITIONAL_INFO]: {
    title: 'section.additionalInfo',
    field: 'additionalInfo',
    validator: (data) => additionalInfoSchema.isValidSync(data.additionalInfo),
    key: BOOKING_STEPS.ADDITIONAL_INFO,
    component: AdditionalInfo
  },
  [BOOKING_STEPS.SELECT_APPOINTMENT]: {
    title: 'section.selectAppointment',
    field: 'appointmentInfo',
    validator: (data) => appointmentSchema.isValidSync(data.appointmentInfo),
    key: BOOKING_STEPS.SELECT_APPOINTMENT,
    component: SelectAppointment
  },
  [BOOKING_STEPS.CONFIRMATION]: {
    title: 'section.confirmation',
    field: null,
    validator: (data) => validationSchema.isValidSync(data),
    key: BOOKING_STEPS.CONFIRMATION,
    component: Confirmation
  },
  [BOOKING_STEPS.STATUS]: {
    title: 'section.status',
    field: null,
    validator: () => true,
    key: BOOKING_STEPS.STATUS,
    component: BookingStatus
  }
};

export default STEP_INFO;
