import type RequestSetting from 'src/interfaces/api/RequestSetting';
import type { AppointmentRequestSettings, OnlineBookingFormData } from './model';
import { capitalize, defaults } from 'lodash';
import { AppointmentSettingsOptions } from 'src/hooks/useAppointmentOptions/types';
import { INITIAL_FORM_DATA } from './helpers';
import { ApiPracticeInfo, AppointmentRequest, Client, Patient, RequestType } from 'src/interfaces';
import { fullNameAndTitle, isDefined } from 'src/utils';
import moment from 'moment-timezone';
import { Visit } from 'src/utils/utmTracking';

export const getAppointmentRequestSettings = (
  requestSetting: RequestSetting
): AppointmentRequestSettings => {
  return {
    confirmationText: requestSetting.appointmentRequestConfirmationText,
    headerText: requestSetting.appointmentRequestHeaderText,
    onlineBookingConfirmationText: requestSetting.onlineBookingConfirmationText,
    requireComments: requestSetting.requireComments,
    appointmentRequestsDisabled: requestSetting.appointmentRequestsDisabled
  };
};

export const getInitialValues = (
  user: Client | undefined,
  appointmentOptionsData: AppointmentSettingsOptions | undefined,
  patient: Patient | undefined,
  lastSubmittedValues?: Partial<OnlineBookingFormData>,
  isAnotherAppointment?: boolean
) => {
  const apiData = {
    clientInfo: {
      fullName: fullNameAndTitle(user),
      emailAddress: user?.email,
      phoneNumber: user?.mobilePhone ?? user?.homePhone ?? user?.workPhone,
      clientId: user?.clientId
    },
    patientInfo: isAnotherAppointment
      ? undefined
      : {
          patientId: patient?.patientId,
          name: patient?.name ? capitalize(patient.name) : undefined,
          breed:
            patient?.breed || patient?.species
              ? capitalize(patient.breed ?? patient.species)
              : undefined,
          age: patient?.birthdate,
          sex: patient?.sex ? capitalize(patient.sex) : undefined,
          isNewPatient: patient ? false : undefined
        },
    additionalInfo: {
      requireComments: appointmentOptionsData?.fieldDisplay.requireComments
    },
    appointmentInfo: {
      allowCategorySelection: appointmentOptionsData?.fieldDisplay.allowCategorySelection,
      useAppointmentTypes: appointmentOptionsData?.fieldDisplay.useAppointmentTypes,
      categoryType: appointmentOptionsData?.fieldDisplay.categoryType
    }
  };
  return Object.assign(defaults(apiData, INITIAL_FORM_DATA), lastSubmittedValues);
};

export const generateSubmissionValues = (
  practiceInfo: ApiPracticeInfo,
  values: OnlineBookingFormData,
  visitData?: Visit
): AppointmentRequest => {
  const {
    notificationSettings: { smsOptIn, preferredContactMethod },
    clientInfo: { fullName, emailAddress, phoneNumber, clientId },
    patientInfo: { name, breed, age, sex, isNewPatient, patientId },
    additionalInfo: { comments, images },
    appointmentInfo: {
      preferredDate,
      preferredTime,
      appointmentOption,
      columnOption,
      useAppointmentTypes,
      categoryType
    }
  } = values;
  if (!preferredDate || !preferredTime) {
    throw new Error('Preferred date and time are required');
  }
  const date = moment.tz(`${preferredDate} ${preferredTime}`, practiceInfo.timeZone).toDate();
  return {
    patientId,
    clientId,
    practiceId: practiceInfo.practiceId,
    sourceId: practiceInfo.sourceId,
    type: RequestType.Appointment,
    newClient: !clientId,
    content: {
      appointmentLength: columnOption?.appointmentLength,
      appointmentType: appointmentOption?.label,
      appointmentTypeId: appointmentOption?.value,
      categoryId: columnOption?.categoryId,
      categoryType,
      column: columnOption?.value,
      comments,
      emailAddress,
      fullName,
      length: columnOption?.appointmentLength,
      origin: 'PetPage - Online Booking',
      petName: name,
      phoneNumber,
      preferredContactMethod,
      preferredDate,
      preferredTime: Intl.DateTimeFormat('en', {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: practiceInfo.timeZone
      }).format(date),
      smsOptIn,
      smsOptOut: !smsOptIn,
      useAppointmentTypes,
      isNewPatient,
      petAge: age,
      petSex: sex,
      breed
    },
    requestImageIds:
      images &&
      Object.values(images)
        .map((image) => image.id)
        .filter(isDefined),
    visitorToken: visitData?.visitorToken,
    visitToken: visitData?.visitToken
  };
};

export const isClientMatch = ({
  practiceInfo,
  clientInfo,
  user
}: {
  practiceInfo: ApiPracticeInfo;
  clientInfo: OnlineBookingFormData['clientInfo'];
  user: Client;
}) => {
  if (!user) return false;
  if (practiceInfo.practiceId !== user?.practiceId) return false;
  if (clientInfo.emailAddress === user?.email) return true;
  if (user.mobilePhone && clientInfo.phoneNumber === user.mobilePhone) return true;
  if (user.homePhone && clientInfo.phoneNumber === user.homePhone) return true;
  if (user.workPhone && clientInfo.phoneNumber === user.workPhone) return true;
  return false;
};
