import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Body, Caption, HoverCard, Row } from 'src/components';
import PatientAvatar from 'src/components/PatientAvatar';
import { Calendar } from 'src/components/kit/ABIcons';
import { Margin, Size } from 'src/constants';
import { usePatient } from 'src/hooks';
import { Patient } from 'src/interfaces/';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import styled from 'styled-components/native';

const DETAIL_ICON_SIZE = 52;

interface Props {
  onPress?: () => void;
  patientId: Patient['patientId'];
  tag?: string;
  description?: string;
  date?: string;
  action?: React.ReactElement;
  isLate?: boolean;
  disabled?: boolean;
  actionCondition?: (patient?: Patient) => boolean;
}
const ItemDetail: React.FC<Props> = ({
  patientId,
  description,
  date,
  action,
  tag,
  isLate,
  disabled,
  onPress,
  actionCondition = () => true
}) => {
  const { t } = useTranslation('todos');
  const { data: patient } = usePatient(patientId);
  const { colors } = useAppTheme();
  return (
    <StyledRow onPress={onPress} disabled={disabled}>
      <StyledAvatar patient={patient} />
      <View style={{ flex: 1 }}>
        <StyledBody>
          {patient ? _.capitalize(patient.name) : t('list.unknown')}
          {!!tag && ` - ${tag}`}
        </StyledBody>
        {!!description && (
          <Caption numberOfLines={1} color={colors.onSurface} style={{ marginTop: Size.X4_S }}>
            {description}
          </Caption>
        )}
        {!!date && (
          <DateRow>
            <CalendarContainer>
              <Calendar
                color={isLate ? colors.error : colors.onSurface}
                width={Size.S}
                height={Size.S}
              />
            </CalendarContainer>
            <Caption color={isLate ? colors.error : colors.onSurface}>{date}</Caption>
          </DateRow>
        )}
      </View>
      {!!action && actionCondition(patient) && (
        <View style={{ flexShrink: 0, alignSelf: 'stretch', justifyContent: 'center' }}>
          {action}
        </View>
      )}
    </StyledRow>
  );
};

export default ItemDetail;

const StyledAvatar = styled(PatientAvatar).attrs({ size: DETAIL_ICON_SIZE })`
  margin-right: ${Margin.Medium}px;
  margin-bottom: ${Size.X4_S}px;
  align-self: center;
`;

const CalendarContainer = styled.View`
  margin-right: ${Margin.Small}px;
  margin-bottom: ${Margin.ExtraSmall}px;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledBody = styled(Body).attrs({ numberOfLines: 1 })<{ isLate?: boolean }>`
  color: ${({ theme }) => theme.colors.onSurface};
`;

const StyledRow = styled(HoverCard).attrs({ hideShadow: true })`
  flex-direction: row;
  margin-vertical: ${Margin.Small}px;
  padding: ${Margin.Small}px;

  justify-content: flex-start;
  align-items: flex-start;
`;

const DateRow = styled(Row)`
  justify-content: flex-start;
`;
