import { ConfirmationSections } from 'src/module/FormKit/model';
import { OnlineBookingFormData } from '../model';
import { BOOKING_STEPS } from './enums';
import { ApiPracticeInfo } from 'src/interfaces';
import moment from 'moment';
import { toLocalDate, toLocalTime } from 'src/utils';
import { mask } from 'react-native-mask-text';
import { INTL_PHONE_MASK, PHONE_MASK } from 'src/constants';
import { ImageHolderSection } from 'src/module/FormKit/components';

const CONFIRMATION_SECTIONS: ConfirmationSections<BOOKING_STEPS, OnlineBookingFormData> = {
  appointmentInfo: {
    title: 'section.appointmentInfo',
    dataKey: 'appointmentInfo',
    fields: {
      appointmentOption: ({ values: { appointmentInfo } }) =>
        appointmentInfo?.appointmentOption?.label ?? '',
      columnOption: ({ values: { appointmentInfo } }) => appointmentInfo?.columnOption?.label ?? '',
      preferredTime: ({ values, practiceInfo }) =>
        toLocalTime(makeMoment(values, practiceInfo), practiceInfo.timeZone),
      preferredDate: ({ values, practiceInfo }) =>
        toLocalDate(makeMoment(values, practiceInfo), practiceInfo.timeZone)
    },
    stateKey: BOOKING_STEPS.SELECT_APPOINTMENT,
    fieldDisplay: 'row'
  },
  clientInfo: {
    title: 'section.clientInfo',
    dataKey: 'clientInfo',
    fields: {
      fullName: true,
      emailAddress: true,
      phoneNumber: ({ values }) =>
        mask(values.clientInfo?.phoneNumber ?? '', [PHONE_MASK, INTL_PHONE_MASK])
    },
    stateKey: BOOKING_STEPS.CLIENT_INFO,
    fieldDisplay: 'row'
  },
  patientInfo: {
    title: 'section.patientInfo',
    dataKey: 'patientInfo',
    fields: {
      name: true,
      breed: true,
      sex: true,
      age: true
    },
    stateKey: BOOKING_STEPS.PATIENT_INFO,
    fieldDisplay: 'row'
  },
  additionalInfo: {
    title: 'section.additionalInfo',
    dataKey: 'additionalInfo',
    fields: {
      comments: true,
      images: ImageHolderSection
    },
    stateKey: BOOKING_STEPS.ADDITIONAL_INFO,
    fieldDisplay: 'column'
  },
  notificationSettings: {
    title: 'section.notificationSettings',
    dataKey: 'notificationSettings',
    fields: {
      smsOptIn: ({ values: { notificationSettings } }) =>
        notificationSettings?.smsOptIn ? 'form.smsTrue' : 'form.smsFalse',
      preferredContactMethod: ({ values: { notificationSettings } }) =>
        `form.${notificationSettings?.preferredContactMethod ?? ''}`
    },
    stateKey: BOOKING_STEPS.NOTIFICATION_SETTINGS,
    fieldDisplay: 'row'
  }
};

const makeMoment = (
  { appointmentInfo }: Partial<OnlineBookingFormData>,
  { timeZone }: ApiPracticeInfo
) =>
  moment.tz(
    `${appointmentInfo?.preferredDate ?? ''} ${appointmentInfo?.preferredTime ?? ''}`,
    timeZone
  );

export default CONFIRMATION_SECTIONS;
