import { ApiPracticeInfo } from 'src/interfaces';
import { AppColors } from 'src/types/paper';
import { AppointmentRequestSettings, OnlineBookingFormData } from '../../BookingProvider/model';
import { FormikErrors } from 'formik';

export enum SUBMISSION_STATE {
  SUBMITTING = 'SUBMITTING',

  MANUAL = 'MANUAL',
  PENDING = 'PENDING',
  SCHEDULED = 'SCHEDULED',

  UNAVAILABLE = 'UNAVAILABLE',
  INVALID = 'INVALID'
}

export enum WRITEBACK_STATUS {
  SUCCESS = 'success',
  FAILURE = 'failure',
  INVALID = 'invalid',
  MANUAL = 'manual_entry_required',
  PENDING = 'pending'
}

export interface RequestStatus {
  id: number;
  writeback_status: WRITEBACK_STATUS;
}

export const getNextState = ({
  isSubmitting,
  isError,
  data,
  values,
  practiceInfo,
  errors
}: {
  isSubmitting: boolean;
  isError: boolean;
  data: RequestStatus | undefined;
  values: Partial<OnlineBookingFormData>;
  practiceInfo: ApiPracticeInfo;
  errors: FormikErrors<OnlineBookingFormData>;
}): SUBMISSION_STATE => {
  if (isError || (!isSubmitting && !values.requestId)) return SUBMISSION_STATE.INVALID;
  if (isSubmitting || (practiceInfo.onlineBooking && !data)) return SUBMISSION_STATE.SUBMITTING;
  if (data?.writeback_status === WRITEBACK_STATUS.INVALID) return SUBMISSION_STATE.INVALID;
  if (data?.writeback_status === WRITEBACK_STATUS.FAILURE) {
    if (errors.appointmentInfo) return SUBMISSION_STATE.UNAVAILABLE;
    return SUBMISSION_STATE.INVALID;
  }
  if (!values.clientInfo?.clientId || !values.patientInfo?.patientId || !practiceInfo.onlineBooking)
    return SUBMISSION_STATE.MANUAL;
  if (data?.writeback_status === WRITEBACK_STATUS.SUCCESS) return SUBMISSION_STATE.SCHEDULED;
  return SUBMISSION_STATE.PENDING;
};

export const isFinalState = (status?: WRITEBACK_STATUS) => {
  if (!status) return false;
  return [WRITEBACK_STATUS.SUCCESS, WRITEBACK_STATUS.FAILURE, WRITEBACK_STATUS.INVALID].includes(
    status
  );
};

export const switchColor = (state: SUBMISSION_STATE, colors: AppColors) => {
  switch (state) {
    case SUBMISSION_STATE.SUBMITTING:
      return colors.warn;
    case SUBMISSION_STATE.UNAVAILABLE:
    case SUBMISSION_STATE.INVALID:
      return colors.error;
    default:
      return colors.primary;
  }
};

export const switchProgress = (state: SUBMISSION_STATE) => {
  switch (state) {
    case SUBMISSION_STATE.PENDING:
    case SUBMISSION_STATE.SUBMITTING:
      return undefined;
    case SUBMISSION_STATE.UNAVAILABLE:
    case SUBMISSION_STATE.INVALID:
      return 0;
    case SUBMISSION_STATE.MANUAL:
    case SUBMISSION_STATE.SCHEDULED:
      return 1;
  }
};

export const getPracticeText = (
  state: SUBMISSION_STATE,
  requestSetting: AppointmentRequestSettings
) => {
  switch (state) {
    case SUBMISSION_STATE.SCHEDULED:
      return requestSetting.onlineBookingConfirmationText;
    case SUBMISSION_STATE.MANUAL:
      return requestSetting.confirmationText;
    default:
      return undefined;
  }
};
