import { PropsWithChildren, useEffect, useState } from 'react';
import { IS_WEB } from 'src/constants';
import ForcedUpdate from 'src/scenes/ForcedUpdate';
import { appNeedsUpdate, getAppStoreVersionInfo } from './helper';
import pkg from '../../../package.json';
import { isDefined } from 'src/utils';

const GRACE_PERIOD_DAYS = 5;

export const ForcedUpdateProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [needsUpdate, setNeedsUpdate] = useState<boolean | undefined>();

  useEffect(() => {
    if (IS_WEB) {
      setNeedsUpdate(false);
    } else {
      void getAppStoreVersionInfo()
        .then((versionInfo) => {
          setNeedsUpdate(
            appNeedsUpdate(
              pkg.version,
              versionInfo.versionNumber,
              versionInfo.releaseDate,
              GRACE_PERIOD_DAYS
            )
          );
        })
        // check for update failed, allow user to use app
        .catch(() => setNeedsUpdate(false));
    }
  }, []);

  if (!isDefined(needsUpdate)) return null;
  if (needsUpdate) return <ForcedUpdate />;
  return children;
};
