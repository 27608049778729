import { useEffect } from 'react';
import { ColumnOption } from 'src/hooks/useAppointmentOptions';
import { NO_PREFERENCE } from 'src/hooks/useAppointmentOptions/extractColumnOptions';
import { OnlineBookingState } from '../model';

type Props = Pick<
  OnlineBookingState,
  | 'values'
  | 'openingsQuery'
  | 'appointmentOptions'
  | 'setFieldValue'
  | 'validateField'
  | 'practiceInfo'
>;

const useFilterAppointmentOptions = ({
  openingsQuery: { data: openings },
  appointmentOptions: { data: appointmentOptions },
  setFieldValue,
  validateField,
  practiceInfo: { timeZone },
  values
}: Props) => {
  useEffect(() => {
    void setFieldValue(
      'appointmentInfo.availableDates',
      openings ? Object.keys(openings).sort() : undefined
    ).then(() => {
      if (values.appointmentInfo.preferredDate) {
        void validateField('appointmentInfo.preferredDate');
      }
    });
  }, [openings, setFieldValue, validateField, values.appointmentInfo.preferredDate]);

  useEffect(() => {
    if (values.appointmentInfo.preferredDate) {
      void setFieldValue(
        'appointmentInfo.availableTimes',
        openings?.[values.appointmentInfo.preferredDate]
      ).then(() => {
        if (values.appointmentInfo.preferredTime) {
          void validateField('appointmentInfo.preferredTime');
        }
      });
    }
  }, [
    openings,
    values.appointmentInfo.preferredDate,
    timeZone,
    values.appointmentInfo.preferredTime,
    setFieldValue,
    validateField
  ]);

  useEffect(() => {
    let availableColumns: ColumnOption[] = [];

    if (!appointmentOptions?.columns) {
      return;
    }
    if (values.appointmentInfo?.useAppointmentTypes) {
      availableColumns = appointmentOptions.columns.filter(
        (columnOption: ColumnOption) =>
          columnOption.value === NO_PREFERENCE ||
          (columnOption.colKey &&
            values.appointmentInfo.appointmentOption?.categories?.includes(columnOption.colKey)) ||
          (columnOption.duplicateOfAppointmentCategoryId &&
            values.appointmentInfo.appointmentOption?.categories?.includes(
              columnOption.duplicateOfAppointmentCategoryId
            ))
      );
    } else {
      availableColumns = appointmentOptions.columns;
    }

    void setFieldValue('appointmentInfo.availableColumns', availableColumns);
    if (values.appointmentInfo.columnOption) {
      void validateField('appointmentInfo.columnOption');
    }
  }, [
    values.appointmentInfo?.useAppointmentTypes,
    values.appointmentInfo.appointmentOption?.categories,
    setFieldValue,
    appointmentOptions?.columns,
    values.appointmentInfo.columnOption,
    validateField
  ]);
};

export default useFilterAppointmentOptions;
