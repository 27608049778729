import { noop, pick } from 'lodash';
import React, { createContext, useContext, useEffect, useRef } from 'react';
import usePracticeInfo from 'src/hooks/react-query/usePracticeInfo';
import { isDefined } from 'src/utils';
import { setupDataLayer, setupGoogleAnalytics, setupTagManager } from './helper.web';
import { GoogleTagManagerProps } from './model';
import sentry from 'src/utils/sentry';

interface Context {
  pushEvent: (event: any) => void;
}

const defaultContext: Context = {
  pushEvent: noop
};

const TagManagerContext = createContext(defaultContext);

export const useGoogleTagManager = () => useContext(TagManagerContext);

const GoogleAnalytics: React.FC<GoogleTagManagerProps> = ({ practiceShortName, children }) => {
  const { data: practiceInfo } = usePracticeInfo(practiceShortName);

  const practiceInfoRef = useRef(practiceInfo);
  useEffect(() => {
    practiceInfoRef.current = practiceInfo;
  }, [practiceInfo]);

  useEffect(setupDataLayer, []);

  useEffect(() => {
    // Set up google tag manager container id 1
    if (practiceInfo?.useGoogleTagManager && practiceInfo?.googleTagManagerContainerId) {
      return setupTagManager(practiceInfo.googleTagManagerContainerId);
    }
  }, [practiceInfo?.googleTagManagerContainerId, practiceInfo?.useGoogleTagManager]);

  useEffect(() => {
    // set up google tag manager container id 2
    if (practiceInfo?.useGoogleTagManager && practiceInfo?.googleTagManagerContainerId2) {
      return setupTagManager(practiceInfo.googleTagManagerContainerId2);
    }
  }, [practiceInfo?.googleTagManagerContainerId2, practiceInfo?.useGoogleTagManager]);

  useEffect(() => {
    // setup google analytics
    if (practiceInfo?.googleAnalyticsMeasurementId) {
      return setupGoogleAnalytics(practiceInfo.googleAnalyticsMeasurementId);
    }
  }, [practiceInfo?.googleAnalyticsMeasurementId]);

  const eventQueue = useRef<NodeJS.Timeout[]>([]);
  useEffect(
    () => () => {
      for (const event of eventQueue.current) {
        clearTimeout(event);
      }
    },
    []
  );

  const pushEvent = useRef((event: string, times = 0, d: any = undefined) => {
    const dataLayer = (window as unknown as { dataLayer: any[] }).dataLayer;
    const data = d ?? {
      event,
      time: Date.now() / 1000
    };
    if (
      practiceInfoRef.current &&
      !practiceInfoRef.current.useGoogleTagManager &&
      !practiceInfoRef.current.googleAnalyticsMeasurementId
    ) {
      return;
    }
    if (!practiceInfoRef.current || !isDefined(dataLayer) || dataLayer.length < 1) {
      // We check if the data layer is empty or less than one because the above scripts attach events once they are ready.
      if (times > 2) {
        // after attempting to push 3 times, give up and report
        console.debug('Failed to send event', event);
        sentry.addBreadcrumb({
          category: 'google-analytics',
          message: 'Failed to push event',
          level: 'warning',
          data: {
            event,
            data: pick(practiceInfoRef.current, [
              'googleAnalyticsMeasurementId',
              'googleTagManagerContainerId',
              'googleTagManagerContainerId2'
            ])
          }
        });
        return;
      }
      console.debug('Queueing event', event);
      eventQueue.current.push(
        setTimeout(() => {
          console.debug('Retrying event', event);
          pushEvent.current(event, times + 1, data);
        }, 1000)
      );
    } else {
      console.debug('Sent event', event);
      dataLayer.push(data);
    }
  });

  return (
    <TagManagerContext.Provider value={{ pushEvent: pushEvent.current }}>
      {children}
    </TagManagerContext.Provider>
  );
};

export default GoogleAnalytics;
