import React, { useCallback, useMemo } from 'react';
import { useBookingState } from '../BookingProvider';
import LayoutController from 'src/module/FormKit/components/LayoutController';
import { BOOKING_STEPS, CONFIRMATION_SECTIONS } from '../BookingProvider/helpers';
import { useAuth } from 'src/providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import useGoBack from 'src/hooks/useGoBack';
import _ from 'lodash';
import STEP_INFO from '../BookingProvider/helpers/stepInfo';
import { getIn } from 'formik';

const OBLayoutController: React.FC = () => {
  const { t } = useTranslation();
  const exit = useGoBack();

  const bookingStateResult = useBookingState();
  const {
    practiceInfo,
    state: bookingState,
    errors,
    isSubmitting,
    values,
    goToStep,
    advance,
    goBack
  } = bookingStateResult;

  const { isLoggedIn } = useAuth();

  const hasCompleted = !!bookingState.visited[BOOKING_STEPS.CONFIRMATION];

  const currentStepInfo = useMemo(() => STEP_INFO[bookingState.current], [bookingState]);

  const disableTabNav =
    !!getIn(errors, 'additionalInfo.images') ||
    isSubmitting ||
    bookingState.current === BOOKING_STEPS.STATUS;
  const disableNav = !currentStepInfo?.validator?.(values) || disableTabNav;

  const canExit = [BOOKING_STEPS.STATUS, BOOKING_STEPS.CLIENT_INFO].includes(bookingState.current);

  const showReturnToConfirmation =
    hasCompleted &&
    ![BOOKING_STEPS.CONFIRMATION, BOOKING_STEPS.STATUS].includes(bookingState.current);

  const showReturnToPetPage = bookingState.current === BOOKING_STEPS.STATUS && !!isLoggedIn;

  const goToConfirmation = useCallback(() => {
    goToStep(BOOKING_STEPS.CONFIRMATION);
  }, [goToStep]);

  const errorMessage = useMemo(() => {
    if (getIn(errors, 'clientInfo.acceptConditions')) {
      return t(`onlineBooking:${String(getIn(errors, 'clientInfo.acceptConditions'))}`);
    }
    if (getIn(errors, 'additionalInfo.images')) {
      return getIn(errors, 'additionalInfo.images');
    }
  }, [errors, t]);

  const { bottomButtonText, bottomButtonAction } = useMemo(() => {
    if (bookingState.current === BOOKING_STEPS.STATUS) {
      return {
        bottomButtonText: t('onlineBooking:returnToPetPage'),
        bottomButtonAction: exit
      };
    } else if (bookingState.current === BOOKING_STEPS.CONFIRMATION) {
      return {
        bottomButtonText: t('submit'),
        bottomButtonAction: advance
      };
    } else {
      return {
        bottomButtonText: hasCompleted ? t('onlineBooking:confirmation.return') : t('continue'),
        bottomButtonAction: hasCompleted ? goToConfirmation : advance
      };
    }
  }, [advance, bookingState, exit, goToConfirmation, hasCompleted, t]);

  const validationResults = useMemo(
    () =>
      _(STEP_INFO)
        .mapValues((info) => info.validator(values))
        .value(),
    [values]
  );
  const Component = STEP_INFO[bookingState.current]?.component;

  return (
    <LayoutController
      title={t(`onlineBooking:${currentStepInfo?.title}`)}
      practiceInfo={practiceInfo}
      currentStep={bookingState.current}
      disableTabNav={disableTabNav}
      disableNav={disableNav}
      exit={exit}
      canExit={canExit}
      showReturnToConfirmation={showReturnToConfirmation}
      showReturnToPetPage={showReturnToPetPage}
      goToConfirmation={goToConfirmation}
      goBack={goBack}
      advance={advance}
      isSubmitting={isSubmitting}
      showContinueButton={bookingState.current !== BOOKING_STEPS.STATUS}
      continueText={
        bookingState.current === BOOKING_STEPS.CONFIRMATION ? t('submit') : t('continue')
      }
      errorMessage={errorMessage}
      bottomButtonAction={bottomButtonAction}
      bottomButtonText={bottomButtonText}
      bottomButtonDisabled={disableNav && bookingState.current !== BOOKING_STEPS.STATUS}
      goToStep={goToStep}
      visited={bookingState.visited}
      validationResults={validationResults}
      stepInfo={STEP_INFO}
    >
      <Component {...bookingStateResult} confirmationSections={CONFIRMATION_SECTIONS} />
    </LayoutController>
  );
};

export default OBLayoutController;
