export const setupTagManager = (containerId: string) => {
  const script = document.createElement('script');
  script.innerHTML = googleTagManagerScript(containerId);

  document.body.appendChild(script);

  const noscript = document.createElement('noscript');

  const iframe = document.createElement('iframe');
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${containerId}`;
  iframe.style.display = 'none';
  iframe.height = '0';
  iframe.width = '0';
  iframe.style.display = 'none';
  noscript.appendChild(iframe);

  document.body.appendChild(noscript);

  console.debug('Initialized googleTagManagerContainer', containerId);
  return () => {
    document.body.removeChild(script);
    document.body.removeChild(noscript);
  };
};

export const setupGoogleAnalytics = (measurementsId: string) => {
  const scriptAsync = document.createElement('script');
  scriptAsync.async = true;
  scriptAsync.src = `https://www.googletagmanager.com/gtag/js?id=${measurementsId}`;
  const script = document.createElement('script');
  script.innerHTML = googleAnalyticsScript(measurementsId);

  document.body.appendChild(scriptAsync);
  document.body.appendChild(script);
  console.debug('Initialized GoogleAnalytics', measurementsId);

  return () => {
    document.body.removeChild(scriptAsync);
    document.body.removeChild(script);
  };
};

export const setupDataLayer = () => {
  const dataLayerScript = document.createElement('script');
  dataLayerScript.innerHTML = `
                dataLayer = [{
                'gtm.blacklist': ['html']
            }];`;
  document.body.appendChild(dataLayerScript);
  return () => {
    document.body.removeChild(dataLayerScript);
    (window as unknown as any).dataLayer = [];
  };
};

const googleTagManagerScript = (id: string) => `
        (function(w,d,s,l,i){
            w[l]=w[l]||[];
            w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),
            dl=l!='dataLayer'?'&l='+l:'';
            j.async=true;
            j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
            f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${id}');`;

const googleAnalyticsScript = (id: string) => `
window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${id}');
        gtag('get', '${id}', 'client_id', function(ret){
            window.googleClientId = ret;
        });
`;
