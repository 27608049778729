import { PropsWithChildren, useCallback, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components/native';
import LeftNavBar, { StepInfo } from './LeftNavBar';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Margin, Size } from 'src/constants';
import { LEFT_HERO_HEIGHT, LEFT_HERO_WIDTH, MAX_CARD_HEIGHT, MAX_CARD_WIDTH } from './constants';
import { containers } from 'src/theme/globalStyles';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { BottomButton, CPrimaryButton, HoverButton } from 'src/components';
import { Divider } from 'react-native-paper';
import { useTranslation } from 'react-i18next';
import { ErrorText } from './shared';
import { ChevronLeft, X } from 'react-native-feather';
import { LayoutAnimation, Pressable } from 'react-native';
import TopNavBar from './TopNavBar';
import getOnlineBookingHero from 'src/utils/theme/getOnlineBookingHero';
import getOnlineBookingBackground from 'src/utils/theme/getOnlineBookingBackground';
import { ApiPracticeInfo } from 'src/interfaces';

interface Props<StateEnum extends number> extends PropsWithChildren {
  practiceInfo: ApiPracticeInfo;
  currentStep: StateEnum;
  disableTabNav: boolean;
  disableNav: boolean;
  canExit: boolean;
  showReturnToConfirmation: boolean;
  showReturnToPetPage: boolean;
  showContinueButton: boolean;
  goToConfirmation: () => void;
  goToStep: (step: StateEnum) => void;
  goBack: () => void;
  advance: () => void;
  exit: () => void;
  errorMessage?: string;
  isSubmitting: boolean;
  continueText: string;
  bottomButtonText: string;
  bottomButtonAction: () => void;
  bottomButtonDisabled: boolean;
  title: string;
  visited: Partial<Record<StateEnum, boolean>>;
  validationResults: Record<StateEnum, boolean>;
  stepInfo: StepInfo<StateEnum>;
}

const LayoutController = function <StateEnum extends number>({
  children,
  ...props
}: Props<StateEnum>): ReturnType<React.FC<Props<StateEnum>>> {
  const {
    practiceInfo,
    currentStep,
    disableTabNav,
    disableNav,
    exit,
    canExit,
    showReturnToConfirmation,
    showReturnToPetPage,
    goToConfirmation,
    goBack,
    advance,
    errorMessage,
    isSubmitting,
    showContinueButton,
    continueText,
    bottomButtonAction,
    bottomButtonText,
    bottomButtonDisabled,
    title,
    visited,
    goToStep,
    validationResults,
    stepInfo
  } = props;
  const { setOptions } = useNavigation();
  const { t } = useTranslation('common');

  const {
    previewThemeSet,
    removePreview,
    colors,
    viewMode: { isMobile, isWeb }
  } = useAppTheme();
  useFocusEffect(
    useCallback(() => {
      if (practiceInfo?.theme?.custom) {
        previewThemeSet(practiceInfo.theme.custom);
        return removePreview;
      }
    }, [practiceInfo?.theme?.custom, previewThemeSet, removePreview])
  );

  useLayoutEffect(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }, [currentStep]);

  useEffect(() => {
    if (isMobile) {
      setOptions({
        headerLeft: () =>
          canExit ? (
            <Pressable onPress={exit} style={{ paddingLeft: Margin.Medium }}>
              <X width={Size.L} color={colors.onPrimary} />
            </Pressable>
          ) : (
            <Pressable
              onPress={goBack}
              style={{ paddingLeft: Margin.Medium }}
              disabled={disableTabNav}
            >
              <ChevronLeft
                width={Size.L}
                color={disableTabNav ? colors.disabled : colors.onPrimary}
              />
            </Pressable>
          ),
        title
      });
    }
  }, [
    canExit,
    colors.disabled,
    colors.onPrimary,
    disableTabNav,
    exit,
    goBack,
    isMobile,
    setOptions,
    title
  ]);

  if (isMobile) {
    return (
      <>
        <TopNavBar {...props} />
        {children}
        <BottomButton disabled={bottomButtonDisabled} onPress={bottomButtonAction}>
          {bottomButtonText}
        </BottomButton>
      </>
    );
  } else {
    return (
      <>
        <FullScreen>
          <BackgroundPattern
            source={getOnlineBookingBackground(practiceInfo.appointmentBooking?.backgroundPattern)}
          />
          <LeftNavBar
            disableNav={disableTabNav}
            currentStep={currentStep}
            visited={visited}
            stepInfo={stepInfo}
            validationResults={validationResults}
            goToStep={goToStep}
            practiceInfo={practiceInfo}
          />
          <Container>
            <ContentCard style={[containers.shadow]}>
              {isWeb && (
                <LeftHero
                  source={getOnlineBookingHero(practiceInfo.appointmentBooking?.leftImage)}
                />
              )}
              <OuterContainer>
                {children}
                <Divider />
                <BottomNavigation>
                  {!canExit && (
                    <CPrimaryButton mode='outlined' onPress={goBack}>
                      {t('back')}
                    </CPrimaryButton>
                  )}

                  {showReturnToPetPage && (
                    <CPrimaryButton disabled={isSubmitting} onPress={exit}>
                      {t('onlineBooking:returnToPetPage')}
                    </CPrimaryButton>
                  )}
                  {showContinueButton && (
                    <CPrimaryButton disabled={disableNav} onPress={advance}>
                      {continueText}
                    </CPrimaryButton>
                  )}
                  {!!errorMessage && <ErrorText>{errorMessage}</ErrorText>}
                  {showReturnToConfirmation && (
                    <HoverButton
                      disabled={disableNav}
                      style={{ marginLeft: 'auto' }}
                      labelStyle={{ textAlign: 'center' }}
                      onPress={goToConfirmation}
                    >
                      {t('onlineBooking:confirmation.returnNewline')}
                    </HoverButton>
                  )}
                </BottomNavigation>
              </OuterContainer>
            </ContentCard>
          </Container>
        </FullScreen>
      </>
    );
  }
};

export default LayoutController;

const BackgroundPattern = styled.ImageBackground.attrs({
  resizeMode: 'repeat'
})`
  opacity: 0.2;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const LeftHero = styled.Image`
  width: ${LEFT_HERO_WIDTH}px;
  height: ${LEFT_HERO_HEIGHT}px;
`;

const FullScreen = styled.View`
  flex: 1;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const OuterContainer = styled.View`
  flex: 1;
  align-self: stretch;
  max-width: ${MAX_CARD_HEIGHT}px;
`;

const Container = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: ${Size.X3_L}px;
`;

const ContentCard = styled.View`
  width: 100%;
  max-width: ${({ theme }) =>
    theme.viewMode.isWeb ? MAX_CARD_WIDTH : MAX_CARD_WIDTH - LEFT_HERO_WIDTH}px;
  height: 100%;
  ${({ theme: { viewMode } }) =>
    viewMode.isTablet && !viewMode.isLandscape ? '' : `max-height: ${MAX_CARD_HEIGHT}px;`}
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.surface};
  border-radius: ${({ theme }) => theme.roundness}px;
  overflow: hidden;
`;

const BottomNavigation = styled.View`
  flex-direction: row;
  align-items: center;
  padding: ${Margin.ExtraLarge}px;
  gap: ${Margin.Medium}px;
`;
