import { OnlineBookingFormData } from '../model';

const INITIAL_FORM_DATA: OnlineBookingFormData = {
  clientInfo: {
    fullName: '',
    phoneNumber: '',
    emailAddress: '',
    acceptConditions: false
  },
  patientInfo: {},
  appointmentInfo: {},
  notificationSettings: {},
  additionalInfo: {}
};

export default INITIAL_FORM_DATA;
