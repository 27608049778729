import React, { useLayoutEffect, useMemo, useRef } from 'react';
import styled from 'styled-components/native';
import { getStepState } from '../helper';
import { Size } from 'src/constants';
import { AppColors } from 'src/types/paper';
import { colorShade, transparent } from 'src/utils';
import { Animated } from 'react-native';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Info, StepInfo } from './LeftNavBar';
import { omit } from 'lodash';
import { STEP_STATUS } from './constants';

interface Props<StateEnum extends number> {
  disableNav: boolean;
  validationResults: Record<string, boolean>;
  visited: Partial<Record<StateEnum, boolean>>;
  currentStep: StateEnum;
  goToStep: (step: StateEnum) => void;
  stepInfo: StepInfo<StateEnum>;
}

const TopNavBar = function <StateEnum extends number>({
  disableNav,
  validationResults,
  visited,
  currentStep,
  goToStep,
  stepInfo
}: Props<StateEnum>): ReturnType<React.FC<Props<StateEnum>>> {
  const {
    viewMode: { windowWidth }
  } = useAppTheme();
  const indicatorPosition = useRef(new Animated.Value(0));

  const { countableSteps, lastStep } = useMemo(() => {
    const lastStep = Math.max(...Object.keys(stepInfo).map((k) => (k === '' ? NaN : +k)));
    return {
      countableSteps: omit(stepInfo, lastStep),
      lastStep
    };
  }, [stepInfo]);

  useLayoutEffect(() => {
    Animated.timing(indicatorPosition.current, {
      toValue: +currentStep * stepWidth(windowWidth, 0.25, lastStep),
      duration: 300,
      useNativeDriver: false
    }).start();
  }, [currentStep, windowWidth, lastStep]);

  return (
    <TopBarContainer>
      {Object.values<Info<StateEnum>>(countableSteps).map((step) => {
        const status = getStepState(
          validationResults[step.key],
          !!visited[step.key],
          currentStep === step.key
        );
        return (
          <TabBarIndicator
            key={step.key}
            hitSlop={Size.XS}
            disabled={[STEP_STATUS.ACTIVE, STEP_STATUS.INCOMPLETE].includes(status) || disableNav}
            disableNav={disableNav}
            status={status}
            step={step.key}
            totalSteps={lastStep}
            onPress={() => goToStep(step.key)}
          />
        );
      })}
      <TabBarHighlight style={{ left: indicatorPosition.current }} stepCount={lastStep} />
    </TopBarContainer>
  );
};

export default TopNavBar;

const TopBarContainer = styled.View`
  flex-direction: row;
  align-items: stretch;
  height: ${Size.XS}px;
`;

const TabBarIndicator = styled.Pressable<{
  status: STEP_STATUS;
  step: number;
  totalSteps: number;
  disableNav: boolean;
}>`
  flex: 1;
  background-color: ${({ theme, status }) => barIndicatorColor(status, theme.colors)};
  border-right-width: ${({ step, totalSteps }) => (step === totalSteps ? 0 : 0.5)}px;
  border-left-width: ${({ step }) => (step === 0 ? 0 : 0.5)}px;
  border-right-color: ${({ theme }) => theme.colors.surface};
  border-left-color: ${({ theme }) => theme.colors.surface};
  opacity: ${({ disableNav }) => (disableNav ? 0.5 : 1)};
`;

const TabBarHighlight = styled(Animated.View)<{ stepCount: number }>`
  width: ${({ theme, stepCount }) => stepWidth(theme.viewMode.windowWidth, -2, stepCount)}px;
  height: 100%;
  background-color: ${({ theme }) => colorShade(theme.colors.primary, 0.7)};
  position: absolute;
  bottom: 0;
  pointer-events: none;
`;

const barIndicatorColor = (status: STEP_STATUS, colors: AppColors) => {
  switch (status) {
    case STEP_STATUS.INCOMPLETE:
      return transparent(colors.primary, 0.2);
    case STEP_STATUS.ERROR:
      return transparent(colors.error, 0.5);
    default:
      return colorShade(colors.primary, 0.8);
  }
};

const stepWidth = (windowWidth: number, compensation: number, stepCount: number) =>
  windowWidth / stepCount + compensation;
