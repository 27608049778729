import React from 'react';
import { FormData, FormState } from '../model';
import { IconSize, Size } from 'src/constants';
import styled from 'styled-components/native';

const ImageHolderSection = function <StateEnum extends number, Data extends FormData>({
  values
}: FormState<StateEnum, Data>): ReturnType<React.FC<FormState<StateEnum, Data>>> {
  return (
    <ImagesHolder>
      {Object.values(values.additionalInfo.images ?? {}).map((image) => (
        <StyledImage key={image.id} source={{ uri: image.image.uri }} />
      ))}
    </ImagesHolder>
  );
};

export default ImageHolderSection;

const ImagesHolder = styled.View`
  gap: ${Size.XS}px;
  flex-direction: row;
  flex-wrap: wrap;
`;

const StyledImage = styled.Image`
  height: ${IconSize.XL}px;
  width: ${IconSize.XL}px;
  flex: 1 0 31%;
  max-width: 32%
  aspect-ratio: 1;
  border-radius: ${({ theme }) => theme.roundness}px;
  background-color: ${({ theme }) => theme.colors.disabled};
`;
