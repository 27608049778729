import { Platform } from 'react-native';
import { Config } from './Config';
import { HOSTNAME, IS_WEB } from 'src/constants';

const getHostname = (): string => {
  if (IS_WEB) {
    return 'localhost';
  }
  if (HOSTNAME) {
    return HOSTNAME;
  }
  if (Platform.OS === 'android') {
    return `10.0.2.2`;
  } else {
    return 'localhost';
  }
};

const firebaseAppId = Platform.select({
  android: '1:859221759384:android:99d12940642616d2c247ed',
  ios: '1:859221759384:ios:d5035204a07b395dc247ed',
  web: '1:859221759384:web:d9050fbd090c22f5c247ed',
  default: ''
});

export const debugConfig: Config = {
  API_URL: `http://${getHostname()}:3000`,
  BASE_URL: `http://${getHostname()}:19006`,
  ENVIRONMENT: 'Debug',
  PUSH_URL: `https://9xpw5erfge.execute-api.us-east-1.amazonaws.com/production`,
  FIREBASE: {
    apiKey: 'AIzaSyDVNuDc9tFS4qYMFZAoPVV1WVma0tPQAHo',
    authDomain: 'allydvm-1.firebaseapp.com',
    projectId: 'allydvm-1',
    storageBucket: 'allydvm-1.appspot.com',
    databaseURL: 'https://allydvm-1.firebaseio.com',
    messagingSenderId: '859221759384',
    appId: firebaseAppId
  },
  DEEPLINK_REDIRECT_DOMAINS: [
    'url5711.notifications.dev.allydvm.com',
    'url1325.messages.dev.allydvm.com'
  ]
};
