import { NavigationContainer } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking } from 'react-native';
import { FocusAwareStatusBar, Headline, PrimaryGradientBackground, Title } from 'src/components';
import { IS_ANDROID, PETPAGE_APP_STORE, Size } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import styled from 'styled-components/native';
import DownloadAppStore from '../Landing/components/DownloadOurApp/DownloadAppStore';
import DownloadPlayStore from '../Landing/components/DownloadOurApp/DownloadPlayStore';

const ForcedUpdate: React.FC = () => {
  const { t } = useTranslation('forcedUpdate');
  const { colors } = useAppTheme();

  const openStore = useCallback(async () => Linking.openURL(PETPAGE_APP_STORE), []);

  return (
    <NavigationContainer>
      <FocusAwareStatusBar barStyle={'light-content'} />
      <PrimaryGradientBackground />
      <Container>
        <Title alignSelf='center' color={colors.onPrimary}>
          {t('subtitle')}
        </Title>
        <StyledHeadline alignSelf='center' color={colors.onPrimary}>
          {t('message')}
        </StyledHeadline>
        <StyledPressable onPress={openStore}>
          {IS_ANDROID ? (
            <DownloadPlayStore width={Size.X5_L} />
          ) : (
            <DownloadAppStore width={Size.X5_L} />
          )}
        </StyledPressable>
      </Container>
    </NavigationContainer>
  );
};

export default ForcedUpdate;

const Container = styled.View`
  flex: 1;
  margin: ${Size.M}px;
  align-items: center;
  justify-content: center;
`;

const StyledHeadline = styled(Headline)`
  text-align: center;
  margin-top: ${Size.S}px;
`;

const StyledPressable = styled.Pressable`
  justify-content: flex-end;
  align-self: center;
  margin-top: ${Size.L}px;
`;
