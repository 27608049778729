import React from 'react';
import styled from 'styled-components/native';
import { Edit2 } from 'react-native-feather';
import { TouchableRipple } from 'react-native-paper';
import { Margin } from 'src/constants';

interface Props {
  onPress: () => void;
}
const EditButton: React.FC<Props> = ({ onPress }) => {
  return (
    <StyleButton onPress={onPress}>
      <Edit />
    </StyleButton>
  );
};

export default EditButton;

const Edit = styled(Edit2)`
  color: ${({ theme }) => theme.colors.primary};
`;

const StyleButton = styled(TouchableRipple)`
  padding: ${Margin.ExtraSmall}px;
  border-radius: ${({ theme }) => theme.roundness}px;
`;
