import { ClientPatientSearchResponse } from 'src/api';
import { MappedClientPatientSearch, PatientInfo } from 'src/module/FormKit/model';

const mapClientPatientSearch = (data: ClientPatientSearchResponse): MappedClientPatientSearch => {
  return {
    ...data,
    patients: data.patients?.map((patient) => {
      const patientInfo: PatientInfo = {
        ...patient,
        breed: patient.species,
        isNewPatient: false
      };
      return patientInfo;
    })
  };
};

export default mapClientPatientSearch;
