import { AppColors } from 'src/types/paper';
import { UseQueryOptions } from 'react-query';
import Practice from './Practice';
import { Species } from 'src/constants';

export interface BasicColors
  extends Pick<
    AppColors,
    | 'primary'
    | 'onPrimary'
    | 'confirmation'
    | 'background'
    | 'surface'
    | 'error'
    | 'text'
    | 'onSurface'
    | 'notification'
    | 'warn'
  > {}

export interface PracticeTheme {
  useCustomTheme: boolean;
  custom?: {
    dark: BasicColors;
    light: BasicColors;
  };
}

export type ApiPracticeInfo = {
  practiceId: Practice['id'];
  sourceId: Practice['sourceId'];
  name: Practice['name'];
  petPageLogo: string | null;
  mobileBannerImage: string | null;
  petSignInImage: string | null;
  theme: PracticeTheme;
  onlineBooking: boolean;
  appointmentBooking: BookingTheme | null;
  website: string | null;
  phoneNumber: string;
  timeZone: string;
  hideBannerImage: boolean;

  useGoogleTagManager?: boolean;
  googleTagManagerContainerId: string;
  googleTagManagerContainerId2: string;
  googleAnalyticsMeasurementId?: string;
};

export type BookingTheme = {
  backgroundPattern: BackgroundPatternCustomization;
  leftImage: LeftImageCustomization;
  loader: LoaderCustomization;
};

export type LoaderCustomization = 'dog_and_cat' | 'empty' | Species;

export enum LeftImageCustomization {
  CANINE_FELINE_EQUINE_EXOTIC = 'canine_feline_equine_exotic',
  CANINE_FELINE_EQUINE = 'canine_feline_equine',
  CANINE_FELINE_EXOTIC_2 = 'canine_feline_exotic_2',
  CANINE_FELINE_EXOTIC = 'canine_feline_exotic',
  DOG_AND_CAT = 'dog_and_cat',
  EQUINE = 'equine',
  EXOTIC = 'exotic',
  FELINE = 'feline'
}

export enum BackgroundPatternCustomization {
  HORSE_SHOES = 'horse_shoes',
  PAWS = 'paws',
  PAWS_AND_HORSESHOES = 'paws_and_horseshoes',
  PAWS_AND_LIZARDS = 'paws_and_lizards'
}

export type Options = Omit<
  UseQueryOptions<any, unknown, any, Array<string | undefined>>,
  'queryFn' | 'queryKey'
>;

export type PracticeInfo = Omit<ApiPracticeInfo, 'theme'> & {
  theme: PracticeTheme;
};
