import React, { useEffect, useMemo, useState } from 'react';
import { SIDEBAR_WIDTH, SIDE_BAR_LOGO_SIZE, STEP_STATUS } from './constants';
import styled from 'styled-components/native';
import { INTL_PHONE_MASK, IconSize, Margin, PHONE_MASK, Language, Size } from 'src/constants';
import { Body, Headline, HoverButton, DropDown } from 'src/components';
import { mask } from 'react-native-mask-text';
import { useTranslation } from 'react-i18next';
import { Weight } from 'src/theme';
import { Check } from 'react-native-feather';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { transparent } from 'src/utils';
import { getStepState, switchColors, switchTextColors } from '../helper';
import { ApiPracticeInfo } from 'src/interfaces';
import { omit } from 'lodash';

const MARKER_SIZE = IconSize.S;

export type Info<StateEnum extends number> = {
  title: string;
  field: string | null;
  validator: (data: any) => boolean;
  key: StateEnum;
};

export type StepInfo<StateEnum extends number> = {
  [key in StateEnum]: Info<StateEnum>;
};

interface Props<StateEnum extends number> {
  disableNav: boolean;
  stepInfo: StepInfo<StateEnum>;
  currentStep: StateEnum;
  visited: Partial<Record<StateEnum, boolean>>;
  validationResults: Record<StateEnum, boolean>;
  goToStep: (step: StateEnum) => void;
  practiceInfo: ApiPracticeInfo;
}

const LeftNavBar = function <StateEnum extends number>({
  disableNav,
  stepInfo,
  currentStep,
  visited,
  validationResults,
  practiceInfo,
  goToStep
}: Props<StateEnum>): ReturnType<React.FC<Props<StateEnum>>> {
  const { colors } = useAppTheme();
  const { t, i18n } = useTranslation('onlineBooking');
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    if (i18n.language !== language) {
      void i18n.changeLanguage(language);
    }
  }, [i18n, language]);

  const countableSteps = useMemo(() => {
    const lastStep = Math.max(...Object.keys(stepInfo).map((k) => (k === '' ? NaN : +k)));
    return omit(stepInfo, lastStep);
  }, [stepInfo]);

  return (
    <SidebarContainer>
      <PracticeInfoContainer>
        {!!practiceInfo.petPageLogo && <PracticeLogo source={{ uri: practiceInfo.petPageLogo }} />}
        <CenteredText as={Headline}>{practiceInfo.name} </CenteredText>
        <CenteredText>{mask(practiceInfo.phoneNumber, [PHONE_MASK, INTL_PHONE_MASK])}</CenteredText>
      </PracticeInfoContainer>
      <NavigationIndicatorContainer>
        {Object.values<Info<StateEnum>>(countableSteps).map((step) => {
          const status = getStepState(
            validationResults[step.key],
            !!visited[step.key],
            currentStep === step.key
          );
          return (
            <IndicatorRow
              disabled={[STEP_STATUS.ACTIVE, STEP_STATUS.INCOMPLETE].includes(status) || disableNav}
              onPress={() => goToStep(step.key)}
              key={step.key}
              icon={
                <IconContainer status={status}>
                  {status === STEP_STATUS.COMPLETE ? (
                    <Check color={colors.surface} width={IconSize.XS} height={IconSize.XS} />
                  ) : (
                    <IconText status={status}>{step.key + 1}</IconText>
                  )}
                  {!!stepInfo[step.key].field && <Marker />}
                </IconContainer>
              }
            >
              <SectionTitle isActive={status === STEP_STATUS.ACTIVE}>{t(step.title)}</SectionTitle>
            </IndicatorRow>
          );
        })}
      </NavigationIndicatorContainer>
      <LanguagePickerContainer>
        <DropDown
          mode='flat'
          label={t('settings:chooseLanguage')}
          options={[
            { label: t('settings:englishOption'), value: Language.en },
            { label: t('settings:spanishOption'), value: Language.es },
            { label: t('settings:frenchOption'), value: Language.fr }
          ]}
          value={language}
          onChange={(value) => {
            setLanguage(value.toString());
          }}
        />
      </LanguagePickerContainer>
    </SidebarContainer>
  );
};

export default LeftNavBar;

const NavigationIndicatorContainer = styled.View`
  padding: ${Margin.Large}px;
  flex: 1;
`;

const SidebarContainer = styled.View`
  align-self: stretch;
  flex-basis: ${SIDEBAR_WIDTH}px;
  background-color: ${({ theme }) => theme.colors.surface};
`;

const PracticeInfoContainer = styled.View`
  gap: ${Margin.Medium}px;
  padding: ${Margin.ExtraLarge}px;
`;

const PracticeLogo = styled.Image.attrs({
  resizeMode: 'contain'
})`
  height: ${SIDE_BAR_LOGO_SIZE}px;
`;

const CenteredText = styled(Body)`
  text-align: center;
  color: ${({ theme }) => theme.colors.onSurface};
`;

const SectionTitle = styled(Body)<{ isActive: boolean }>`
  font-weight: ${({ isActive }) => (isActive ? Weight.BOLD : Weight.REGULAR)};
  color: ${({ theme }) => theme.colors.onSurface};
`;

const IndicatorRow = styled(HoverButton).attrs({
  labelStyle: {
    lineHeight: MARKER_SIZE,
    letterSpacing: 0.5
  },
  numberOfLines: 1
})`
  cursor: pointer;
  flex-direction: row;
  align-items: top;
  justify-content: flex-start;
  gap: ${Margin.Medium}px;
  padding: ${MARKER_SIZE / 2}px;
  overflow: visible;
`;

const IconContainer = styled.View<{ status: STEP_STATUS }>`
  align-items: center;
  justify-content: center;
  width: ${MARKER_SIZE}px;
  height: ${MARKER_SIZE}px;
  border-radius: ${MARKER_SIZE}px;
  background-color: ${({ theme, status }) => switchColors(status, theme.colors)};
`;

const IconText = styled(Body)<{ status: STEP_STATUS }>`
  text-align: center;
  color: ${({ theme, status }) => switchTextColors(status, theme.colors)};
`;

const Marker = styled.View`
  width: 2px;
  height: ${MARKER_SIZE}px;
  background-color: ${({ theme }) => transparent(theme.colors.disabled, 0.1)};
  position: absolute;
  bottom: ${-MARKER_SIZE}px;
`;

const LanguagePickerContainer = styled.View`
  margin: ${Size.X2_S}px;
`;
