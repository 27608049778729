import { useRoute } from '@react-navigation/native';
import { defaults, isEmpty, pick } from 'lodash';
import { useAuth } from 'src/providers/AuthProvider';
import { trackEvent, trackVisit, UTMParams } from 'src/utils/utmTracking';
import { Client } from 'src/interfaces';
import { TRACKING_EVENTS } from 'src/constants';
import { useQuery } from 'react-query';
import { useGoogleTagManager } from 'src/providers/GoogleTagManager/index.web';
import { useEffect } from 'react';

interface Options extends Partial<Pick<Client, 'practiceId' | 'sourceId' | 'clientId'>> {
  initialEventName?: TRACKING_EVENTS;
}
export const useTrackVisit = ({ initialEventName, ...sessionInfo }: Options) => {
  const { params, path } = useRoute();
  const { pushEvent } = useGoogleTagManager();
  const { _user: user, ready } = useAuth();
  const utmParams = pick((params || {}) as UTMParams, [
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'utm_source',
    'utm_term'
  ]);

  useEffect(() => {
    if (initialEventName) {
      pushEvent(initialEventName);
    }
  }, [initialEventName, pushEvent]);

  return useQuery({
    queryKey: ['trackVisit', path, initialEventName, utmParams],
    queryFn: async () => {
      const data = await trackVisit(utmParams, path, defaults(sessionInfo, user));
      if (initialEventName) {
        trackEvent(initialEventName);
      }
      return data?.data;
    },
    enabled: !isEmpty(utmParams) && ready
  });
};

export default useTrackVisit;
