import { AxiosError } from 'axios';
import { compareVersions } from 'compare-versions';
import moment from 'moment';
import { IS_DEBUG } from 'src/constants';
import config from 'src/environment';
import { debugConfig } from 'src/environment/config.debug';

export interface VersionInfo {
  versionNumber: string;
  releaseDate: Date;
}

export const appNeedsUpdate = (
  userAppVersionNumber: string,
  criticalVersionNumber: string,
  releaseDate: Date,
  gracePeriodDays: number
) => {
  const isCriticalReleaseAvailable =
    compareVersions(userAppVersionNumber, criticalVersionNumber) < 0;
  const isPastGracePeriod = moment(releaseDate, 'MM-DD-YYYY')
    .add(gracePeriodDays, 'days')
    .endOf('day')
    .isBefore();
  return isCriticalReleaseAvailable && isPastGracePeriod;
};

export const getAppStoreVersionInfo = async (): Promise<VersionInfo> => {
  try {
    const url = `${IS_DEBUG ? debugConfig.BASE_URL : config.BASE_URL}/version.json`;
    const response = await fetch(url);
    const json = await response.json();
    return { versionNumber: json.versionNumber, releaseDate: json.releaseDate };
  } catch (e) {
    throw e as AxiosError;
  }
};
