import config from 'src/environment';

export const resolveSendGridLink = async (url: string) => {
  if (validateHostname(url)) {
    return followRedirect(url);
  }
  return url;
};

export const validateHostname = (url: string) => {
  const parsed = new URL(url);
  if (parsed.protocol !== 'https:') return false;
  for (const domain of config.DEEPLINK_REDIRECT_DOMAINS) {
    if (parsed.hostname === domain) return true;
  }
  return false;
};

export const followRedirect = async (url: string) => {
  const response = await fetch(url, {
    redirect: 'follow'
  });
  if (response.ok) {
    return response.url;
  }
  return url;
};

export default resolveSendGridLink;
