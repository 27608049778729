import React, { SetStateAction, useCallback, useState } from 'react';
import { ContentContainer, InputContainer, MainText, Row, FormScrollView } from './shared';
import { Headline, TextInput } from 'src/components';
import { useTranslation } from 'react-i18next';
import UploadFiles, { ImageUpload } from 'src/components/UploadFiles';
import { Margin } from 'src/constants';
import styled from 'styled-components/native';
import { FormData, FormState } from '../model';
import { getIn } from 'formik';

const AdditionalInfo = function <StateEnum extends number, Data extends FormData>({
  values,
  setFieldValue,
  validateField,
  errors
}: FormState<StateEnum, Data>): ReturnType<React.FC<FormState<StateEnum, Data>>> {
  const { t } = useTranslation('onlineBooking');

  const [images, setImages] = useState<Record<string, ImageUpload>>(
    values.additionalInfo.images ?? {}
  );
  const onImageChange = useCallback(
    async (action: SetStateAction<Record<string, ImageUpload>>) => {
      setImages((prev) => {
        const value = typeof action === 'function' ? action(prev) : action;
        void setFieldValue('additionalInfo.images', value);
        setTimeout(async () => validateField('additionalInfo.images'), 100);
        return value;
      });
    },
    [setFieldValue, validateField]
  );

  return (
    <FormScrollView>
      <ContentContainer>
        <MainText as={Headline}>{t('additionalInfo.title')}</MainText>
        <MainText>{t('additionalInfo.description')}</MainText>
      </ContentContainer>
      <Row>
        <InputContainer>
          <TextInput
            multiline
            style={{ minHeight: 100 }}
            label={t('form.comments')}
            value={values.additionalInfo?.comments}
            onChangeText={async (text) => setFieldValue('additionalInfo.comments', text, false)}
            onBlur={async () => validateField('additionalInfo.comments')}
            error={!!getIn(errors, 'additionalInfo.comments')}
            msg={t(getIn(errors, 'additionalInfo.comments'))}
          />
        </InputContainer>
      </Row>
      <StyledUpload images={images} onImageChange={onImageChange} buttonMode='outlined' />
    </FormScrollView>
  );
};

export default AdditionalInfo;

const StyledUpload = styled(UploadFiles)`
  flex-direction: column-reverse;
  gap: ${Margin.Large}px;
`;
