import { AppColors } from 'src/types/paper';
import { STEP_STATUS } from './components/constants';
import { transparent } from 'src/utils';

export const switchColors = (stepState: STEP_STATUS, colors: AppColors) => {
  switch (stepState) {
    case STEP_STATUS.ACTIVE:
      return colors.primary;
    case STEP_STATUS.ERROR:
      return colors.error;
    case STEP_STATUS.INCOMPLETE:
      return transparent(colors.disabled, 0.1);
    default:
      return colors.text;
  }
};

export const switchTextColors = (stepState: STEP_STATUS, colors: AppColors) => {
  switch (stepState) {
    case STEP_STATUS.ACTIVE:
      return colors.surface;
    case STEP_STATUS.ERROR:
      return colors.surface;
    default:
      return colors.onSurface;
  }
};

export const getStepState = (valid: boolean, visited: boolean, isActive: boolean): STEP_STATUS => {
  if (isActive) {
    return STEP_STATUS.ACTIVE;
  } else if (!visited) {
    return STEP_STATUS.INCOMPLETE;
  } else if (valid) {
    return STEP_STATUS.COMPLETE;
  } else {
    return STEP_STATUS.ERROR;
  }
};
