import React from 'react';
import { ContentContainer, MainText, FormScrollView } from './shared';
import { Headline } from 'src/components';
import { useTranslation } from 'react-i18next';
import ConfirmationSection from './ConfirmationSection';
import { ConfirmationSections, FormData, FormState, Section } from '../model';

interface Props<StateEnum extends number, Data extends FormData>
  extends FormState<StateEnum, Data> {
  confirmationSections: ConfirmationSections<StateEnum, Data>;
}

const Confirmation = function <StateEnum extends number, Data extends FormData>(
  props: Props<StateEnum, Data>
): ReturnType<React.FC<Props<StateEnum, Data>>> {
  const { confirmationSections } = props;
  const { t } = useTranslation('onlineBooking');

  return (
    <FormScrollView>
      <ContentContainer>
        <MainText as={Headline}>{t('confirmation.title')}</MainText>
        <MainText>{t('confirmation.description')}</MainText>
      </ContentContainer>

      {Object.values<Section<StateEnum, Data>>(confirmationSections).map((sectionProps) => (
        <ConfirmationSection<StateEnum, Data>
          key={String(sectionProps.dataKey)}
          section={confirmationSections[sectionProps.dataKey]}
          {...sectionProps}
          {...props}
        />
      ))}
    </FormScrollView>
  );
};

export default Confirmation;
